import Button from '@atoms/Button/Button';
import { DEFAULT_FOCUS_POINT, FOCUS_POINT } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import StockImage from '@img/stock-image.webp';
import StockImagePlaceholder from '@img/stock-image-placeholder-blur.webp';
import { OverviewItem } from '@type-declarations/overviewItems';
import getInternalRoute from '@utils/getInternalRoute';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import styles from './SliderCard.module.scss';

interface Props {
  item: OverviewItem;
}

export default function SliderCard({ item }: Props) {
  const t = useTranslations();
  const {
    store: { locale },
  } = useStore();

  return (
    <article className={styles.card}>
      <h1 className={clsx(styles.title, 'u-typography-heading-3')}>
        {item.title}
      </h1>
      <Button
        href={getInternalRoute({
          internalLink: { page: [item] },
          locale,
        })}
        className={clsx(styles.cta, 'u-umbrella-link')}
        label={`${t.watch} ${t.page[item.typename]}`}
        icon="arrow-right"
        variant="senary"
      />

      <div className={styles.imageContainer}>
        <div className={styles.gradient} />
        <Image
          src={item.overviewInfo?.image?.sliderUrl || StockImage}
          placeholder="blur"
          blurDataURL={
            item.overviewInfo?.image?.placeholder || StockImagePlaceholder.src
          }
          objectFit="cover"
          objectPosition={
            FOCUS_POINT[
              item.overviewInfo?.image
                ? item.overviewInfo.focusPoint
                : DEFAULT_FOCUS_POINT
            ]
          }
          layout="fill"
          alt={item.overviewInfo?.image?.description || ''}
          className={styles.image}
        />
      </div>
    </article>
  );
}
