import Button from '@atoms/Button/Button';
import useTranslations from '@hooks/useTranslations';

import styles from './Pagination.module.scss';

export interface Props {
  hasNextPage: boolean;
  handleLoadMore: () => void;
  loading?: boolean;
}

function Pagination({ hasNextPage, handleLoadMore, loading = false }: Props) {
  const t = useTranslations();

  if (!hasNextPage) return null;

  return (
    <div className={styles.pagination}>
      <Button
        label={t.showMore}
        onClick={handleLoadMore}
        disabled={loading}
        loading={loading}
        variant="secondary"
      />
    </div>
  );
}

export default Pagination;
