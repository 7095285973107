import useLocale from '@hooks/useLocale';
import useTranslations from '@hooks/useTranslations';
import Card from '@molecules/Card/Card';
import { Events } from '@type-declarations/overviewItems';
import formatDate from '@utils/formatDate';
import getInternalRoute from '@utils/getInternalRoute';
import { useMemo } from 'react';

export default function EventItem({ item }: { item: Events }) {
  const locale = useLocale();
  const t = useTranslations();

  const subtitle = useMemo(() => {
    const location = item.location
      ? ` | ${item.location?.city}, ${item.location?.country}`
      : '';
    return (
      formatDate({
        date: item.startDate,
        locale,
        format: 'd MMMM',
      }) + (location || ` | ${t.online}`)
    );
  }, [item.startDate, locale, item.location, t.online]);

  return (
    <Card
      title={item.overviewInfo.title || item.title}
      subtitle={subtitle}
      ctaLabel={t.viewEvent}
      image={item.overviewInfo.image}
      focusPoint={item.overviewInfo.focusPoint}
      href={getInternalRoute({
        internalLink: { page: [item] },
        locale,
      })}
    />
  );
}
