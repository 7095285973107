import clsx from 'clsx';
import { forwardRef } from 'react';

import styles from './RichText.module.scss';

interface Props {
  text: string;
  className?: string;
}

const RichText = forwardRef<HTMLDivElement, Props>(
  ({ text, className }, ref) => (
    <div
      ref={ref}
      className={clsx(styles.content, className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: text.replaceAll('\r', '<br>').replaceAll('\n', '<br>'),
      }}
    />
  ),
);

RichText.displayName = 'RichText';

export default RichText;
