import { RefObject, useEffect } from 'react';

export default function useContentHeroAnimation(
  ref: RefObject<HTMLElement>,
  className: string,
) {
  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;

      const onTop = window.scrollY === 0;
      if (onTop) {
        ref.current.classList.remove(className);
        return;
      }

      const bottomTouchesBottomScreen =
        ref.current.getBoundingClientRect().bottom <= window.innerHeight;

      if (bottomTouchesBottomScreen) {
        ref.current.classList.add(className);
      } else {
        ref.current.classList.remove(className);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref, className]);
}
