import ImageCard from '@molecules/SliderCard/ImageCard';
import SwiperSection from '@molecules/SwiperSection/SwiperSection';
import { ImageSliderSection as Props } from '@type-declarations/sections';

export default function ImageSliderSection({
  title,
  punchline,
  text,
  cta,
  selection,
  noPadding,
}: Props) {
  return (
    <SwiperSection
      title={title}
      punchline={punchline}
      text={text}
      cta={cta}
      selection={selection}
      noPadding={noPadding}
      SliderCard={ImageCard}
    />
  );
}
