import Button from '@atoms/Button/Button';
import { IconType } from '@atoms/Icon/Icon';
import useLocale from '@hooks/useLocale';
import { ButtonSize, ButtonVariants, Cta } from '@type-declarations/cta';
import getInternalRoute from '@utils/getInternalRoute';

interface Props {
  cta: Cta;
  className?: string;
  ctaAnimation?: boolean;
  variant?: ButtonVariants;
  size?: ButtonSize;
  icon?: IconType | null;
}

function CtaButton({
  cta,
  className,
  variant = 'secondary',
  ctaAnimation = true,
  icon,
  size,
}: Props) {
  const locale = useLocale();

  if (!cta?.link?.length || !cta.link[0].title) return null;

  const link = cta.link[0];

  let ctaLink;
  if (link.typename === 'InternalLink' && link.internalLink) {
    ctaLink = getInternalRoute({
      internalLink: link.internalLink,
      locale,
    });
  } else if (link.typename === 'ExternalLink') {
    ctaLink = link.externalLink;
  } else if (link.typename === 'FileLink') {
    ctaLink = `${link.file.url}?cd=inline`;
  }

  if (!ctaLink) return null;

  return (
    <Button
      href={ctaLink}
      label={link.title}
      className={className}
      noAnimation={!ctaAnimation}
      variant={variant}
      icon={icon}
      size={size}
    />
  );
}

export default CtaButton;
