import Button from '@atoms/Button/Button';
import useMedia from '@hooks/useMedia';
import type { MuxPlayerRefAttributes } from '@mux/mux-player-react';
import MuxPlayer from '@mux/mux-player-react';
import { Video as VideoType } from '@type-declarations/media';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './Video.module.scss';

interface Props {
  video: Partial<VideoType>;
  className?: string | false;
  autoplay?: boolean;
  loop?: boolean;
}

function Video({
  video: { youtubeId, embeddedVideo, alt = '', thumbnail },
  autoplay = false,
  loop = false,
  className,
}: Props) {
  const [videoHasStarted, setVideoHasStarted] = useState(false);
  const videoRef = useRef<MuxPlayerRefAttributes>(null);
  const { ref: containerRef, inView } = useInView();
  const { isMediaPlaying, play, pause } = useMedia({ reference: videoRef });

  useEffect(() => {
    if (!autoplay) return;

    if (inView) {
      play();
    } else {
      pause();
    }
  }, [autoplay, play, pause, inView]);

  useEffect(() => {
    if (inView && autoplay && !videoHasStarted) {
      setVideoHasStarted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div ref={containerRef} className={clsx(styles.mediaContainer, className)}>
      {youtubeId ? (
        <iframe
          className={styles.media}
          src={`https://www.youtube-nocookie.com/embed/${youtubeId}?${
            autoplay && videoHasStarted ? 'autoplay=1&mute=1' : ''
          }${autoplay && loop ? '&' : ''}${loop ? `loop=1&playlist=${youtubeId}` : ''}`}
          allow="autoplay"
          allowFullScreen
          title={alt}
        />
      ) : (
        <>
          <MuxPlayer
            playbackId={embeddedVideo?.playbackId}
            ref={videoRef}
            className={clsx(
              styles.media,
              (!isMediaPlaying || (autoplay && loop)) && styles.withoutControls,
            )}
            title={alt}
            muted={autoplay}
            loop={loop}
            poster={thumbnail?.url}
          />
          {!isMediaPlaying && !autoplay && (
            <div className={styles.buttonContainer}>
              <Button
                icon="play"
                className={styles.button}
                onClick={play}
                label="Play video"
                hideLabel
                variant="primary"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Video;
