import 'swiper/css';

import Icon from '@atoms/Icon/Icon';
import useTranslations from '@hooks/useTranslations';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { Cta } from '@type-declarations/cta';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import SwiperType from 'swiper';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './SwiperSection.module.scss';

interface SwipeItem {
  id: string;
}

interface Props<T> {
  id?: string;
  title?: string;
  punchline?: string;
  text?: string;
  cta?: Cta;
  selection?: T[];
  noPadding?: boolean;
  noBorderTop?: boolean;
  slideSize?: 'sm' | 'md';
  SliderCard: FC<{ item: T }>;
  variant?: 'fullSlider' | 'halfSlider';
  autoplay?: boolean;
}

export default function SwiperSection<T extends SwipeItem>({
  id,
  title,
  punchline,
  text,
  cta,
  selection = [],
  noPadding,
  noBorderTop,
  SliderCard,
  slideSize = 'md',
  autoplay = false,
  variant = 'halfSlider',
}: Props<T>) {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const t = useTranslations();

  const handleNext = () => {
    if (!swiper) return;

    swiper.slideNext();
  };

  const handlePrev = () => {
    if (!swiper) return;

    swiper?.slidePrev();
  };

  useEffect(() => {
    const updateSwiper = () => {
      if (swiper) {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      }
    };

    window.addEventListener('resize', updateSwiper);

    return () => {
      window.removeEventListener('resize', updateSwiper);
    };
  });

  const canNavigate = !isBeginning || !isEnd;

  return (
    <Section
      noPadding={noPadding}
      fullWidth
      className={clsx(
        styles.section,
        styles[slideSize],
        variant && styles[variant],
        noBorderTop && styles.noBorderTop,
      )}
      containerClassName={styles.container}
      id={id || undefined}
    >
      <div className={clsx(styles.item, styles.contentItem)}>
        <SectionContent
          title={title}
          punchline={punchline}
          text={text}
          cta={cta}
          ctaVariant="primary"
          alternativeLayout
          modifier={variant}
        />
      </div>

      <div className={styles.list}>
        {selection?.map((item, i) => (
          <div
            key={item.id}
            className={styles.item}
            data-first={i === 0}
            data-last={i === selection.length - 1}
          >
            <SliderCard item={item} />
          </div>
        ))}
      </div>

      <div className={styles.swiperContainer}>
        <Swiper
          modules={[Autoplay]}
          loopAddBlankSlides
          onSwiper={s => {
            setSwiper(s);
            setIsBeginning(s.isBeginning);
            setIsEnd(s.isEnd);
          }}
          slideNextClass={styles.nextSlide}
          slidePrevClass={styles.prevSlide}
          slideActiveClass={styles.activeSlide}
          className={styles.swiper}
          spaceBetween={8}
          slidesPerView="auto"
          autoplay={
            autoplay && {
              delay: 2000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
            }
          }
          loop={autoplay}
          onSlideChange={s => {
            setIsBeginning(s.isBeginning);
            setIsEnd(s.isEnd);
          }}
          onReachEnd={s => {
            setIsEnd(true);
            setIsBeginning(s.isBeginning);
          }}
          onReachBeginning={s => {
            setIsBeginning(true);
            setIsEnd(s.isEnd);
          }}
          navigation={{ prevEl: styles.prev, nextEl: styles.next }}
        >
          {selection?.map((item, i) => (
            <SwiperSlide
              className={styles.swiperSlide}
              key={item.id}
              data-first={i === 0}
              data-last={i === selection.length - 1}
            >
              <SliderCard item={item} />
            </SwiperSlide>
          ))}
        </Swiper>

        {!!selection?.length && canNavigate && (
          <div className={styles.navContainer}>
            <button
              type="button"
              aria-label={t.previous}
              className={clsx(styles.nav, styles.prev)}
              onClick={handlePrev}
              disabled={isBeginning}
            >
              <Icon className={styles.navIcon} icon="arrow-left" />
            </button>
            <button
              type="button"
              aria-label={t.next}
              className={clsx(styles.nav, styles.next)}
              onClick={handleNext}
              disabled={isEnd}
            >
              <Icon className={styles.navIcon} icon="arrow-right" />
            </button>
          </div>
        )}
      </div>
    </Section>
  );
}
