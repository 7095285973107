import clsx from 'clsx';

import styles from './Tag.module.scss';

interface Props {
  title: string;
  className?: string;
  variant?: 'primary' | 'secondary';
  Type?: 'a' | 'span';
  link?: string;
}

function Tag({
  title,
  className,
  variant = 'primary',
  Type = 'span',
  link,
}: Props) {
  return (
    <Type
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(Type === 'a' ? { href: link } : {})}
      className={clsx(styles.tag, className, variant && styles[variant])}
    >
      <span className={styles.text}>{title}</span>
    </Type>
  );
}

export default Tag;
