import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { TextSection as Props } from '@type-declarations/sections';

function TextSection({
  title,
  punchline,
  text,
  cta,
  fullWidth,
  noPadding,
}: Props) {
  return (
    <Section noPadding={noPadding}>
      <SectionContent
        title={title}
        punchline={punchline}
        text={text}
        cta={cta}
        ctaVariant="primary"
        fullWidth={fullWidth}
      />
    </Section>
  );
}

export default TextSection;
