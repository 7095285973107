import Icon, { IconType } from '@atoms/Icon/Icon';
import RichText from '@atoms/RichText/RichText';
import type { Question as QuestionType } from '@type-declarations/faq';
import { CSSProperties, useEffect, useRef, useState } from 'react';

import styles from './Question.module.scss';

interface Props {
  item: QuestionType;
  id: string;
  isOpen: boolean;
  handleAccordion: () => void;
}

export default function Question({ item, id, isOpen, handleAccordion }: Props) {
  const toggleId = `${id}-toggle`;
  const panelId = `${id}-panel`;
  const icon: IconType = isOpen ? 'remove' : 'plus';

  const [itemHeight, setItemHeight] = useState<string>('0');
  const ref = useRef<HTMLDivElement>(null);

  const updateItemHeight = () => {
    setItemHeight(`calc(${ref?.current?.offsetHeight || 0}px + 1.5rem)`);
  };

  useEffect(() => {
    updateItemHeight();
    window.addEventListener('resize', updateItemHeight);

    return () => {
      window.removeEventListener('resize', updateItemHeight);
    };
  }, []);

  const style = { '--item-height': isOpen ? itemHeight : 0 } as CSSProperties;

  return (
    <div className={styles.faqItem}>
      <dt className={styles.header}>
        <button
          id={toggleId}
          onClick={handleAccordion}
          className={styles.accordionButton}
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-controls={panelId}
          type="button"
        >
          {item.question}
          <span className={styles.iconContainer} aria-hidden="true">
            <Icon className={styles.svgIcon} icon={icon} />
          </span>
        </button>
      </dt>
      <dd
        aria-labelledby={toggleId}
        className={styles.answer}
        id={panelId}
        style={style}
      >
        <RichText text={item.answer} className={styles.richText} ref={ref} />
      </dd>
    </div>
  );
}
