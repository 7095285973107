import CardGrid from '@organisms/CardGrid/CardGrid';
import { EventSection as EventSectionProps } from '@type-declarations/sections';
import { useMemo } from 'react';

function EventSection({ title, punchline, text, events }: EventSectionProps) {
  const items = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setMinutes(-today.getTimezoneOffset());

    return events
      .filter(event => new Date(event.startDate).getTime() >= today.getTime())
      .sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
      );
  }, [events]);

  if (items.length === 0) {
    return null;
  }

  return (
    <CardGrid
      title={title}
      punchline={punchline}
      text={text}
      items={items}
      alternativeLayout
    />
  );
}

export default EventSection;
