import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './Section.module.scss';

interface Props {
  id?: string;
  className?: string;
  containerClassName?: string | false;
  noPadding?: boolean;
  smallPaddingTop?: boolean;
  fullWidth?: boolean;
}

export default function Section({
  className,
  containerClassName,
  children,
  id,
  noPadding = false,
  fullWidth = false,
  smallPaddingTop = false,
}: PropsWithChildren<Props>) {
  return (
    <section
      id={id}
      className={clsx(
        styles.section,
        className,
        noPadding && styles.noPadding,
        smallPaddingTop && styles.smallPaddingTop,
      )}
    >
      <div
        className={clsx(!fullWidth && 'u-container-width', containerClassName)}
      >
        {children}
      </div>
    </section>
  );
}
