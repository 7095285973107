import clsx from 'clsx';

import Tag from './Tag';
import styles from './Tags.module.scss';

interface Props {
  tags: { id: string; title: string; slug: string }[];
  className?: string;
  variant?: 'primary' | 'secondary';
  Type?: 'a';
}

export default function Tags({ tags, className, variant, Type }: Props) {
  return (
    <ul className={clsx(styles.tags, 'u-list-clean', className)}>
      {tags.map(({ id, title, slug }) => (
        <li key={id}>
          <Tag
            title={title}
            variant={variant}
            Type={Type}
            link={Type === 'a' ? `#${slug}` : ''}
          />
        </li>
      ))}
    </ul>
  );
}
