import {
  ImageSliderSection,
  UnmappedImageSliderSection,
} from '@type-declarations/sections';
import { ImageSlide } from '@type-declarations/slider';

export function imageSliderSectionMap(
  section: UnmappedImageSliderSection,
): ImageSliderSection {
  return {
    ...section,
    selection: section.selection?.map(
      item =>
        ({
          ...item,
          cta: { link: item.cta },
        }) as ImageSlide,
    ),
  };
}
