import Video from '@atoms/Video/Video';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { VideoSection as Props } from '@type-declarations/sections';
import clsx from 'clsx';

import styles from './VideoSection.module.scss';

function VideoSection({ title, text, cta, video, big, autoplay, loop }: Props) {
  if (!video?.embeddedVideo?.playbackId && !video?.youtubeId) {
    return null;
  }

  return (
    <Section>
      <SectionContent
        title={title}
        text={text}
        cta={cta}
        ctaVariant="tertiary"
      />

      <div
        className={clsx(
          'u-container-grid',
          styles.videoWrapper,
          (title || text || cta) && styles.hasContent,
        )}
      >
        <Video
          video={video}
          className={clsx(styles.video, !big && styles.small)}
          autoplay={autoplay}
          loop={loop}
        />
      </div>
    </Section>
  );
}

export default VideoSection;
