import Questions from '@molecules/Questions/Questions';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { FaqSection as Props } from '@type-declarations/sections';

function FaqSection({
  title,
  text,
  cta,
  questions,
  firstQuestionOpen,
  noPadding,
  id,
}: Props) {
  return (
    <Section noPadding={noPadding} id={id}>
      <SectionContent
        title={title}
        text={text}
        cta={cta}
        ctaVariant="tertiary"
        fullWidth
      />

      <SectionContent className="u-mt-vertical-spacing">
        <Questions
          questions={questions}
          firstQuestionOpen={firstQuestionOpen}
        />
      </SectionContent>
    </Section>
  );
}

export default FaqSection;
