import { Locale } from '@type-declarations/locale';
import { addMinutes, format, parseISO } from 'date-fns';
import { enGB, fr, nl, nlBE } from 'date-fns/locale';

const formatDate = ({
  date,
  format: dateFormat = 'd MMMM y',
  locale = 'nl',
}: {
  date: string;
  format?: string;
  locale?: Locale;
}) => {
  const locales = {
    nl,
    fr,
    en: enGB,
    be: nlBE,
  };

  const isoDate = parseISO(date);
  const timezoneOffsetMinutes = isoDate.getTimezoneOffset();
  const adjustedDate = addMinutes(isoDate, timezoneOffsetMinutes);

  return format(adjustedDate, dateFormat, {
    locale: locales[locale],
  });
};

export default formatDate;
