import RichText from '@atoms/RichText/RichText';
import CtaButton from '@molecules/CtaButton/CtaButton';
import DogEar from '@svg/dog-ear.svg';
import { ContentSlide } from '@type-declarations/contentSlide';
import clsx from 'clsx';

import styles from './ContentCard.module.scss';

interface Props {
  item: ContentSlide;
  first?: boolean;
  last?: boolean;
}

export default function ContentCard({
  item,
  first = false,
  last = false,
}: Props) {
  return (
    <article className={styles.card}>
      {!first && <DogEar className={clsx(styles.dogEar, styles.out)} />}
      {!last && <DogEar className={clsx(styles.dogEar, styles.in)} />}
      {item.title && (
        <h1
          className={clsx(
            styles.title,
            'u-mb-6',
            'u-typography-heading-content-card',
          )}
        >
          {item.title}
        </h1>
      )}
      {item.text && <RichText text={item.text} className={styles.text} />}
      {item.link && (
        <CtaButton
          cta={{ id: item.id, link: item.link }}
          className={styles.ctaButton}
          variant="text"
        />
      )}
    </article>
  );
}
