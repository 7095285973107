import { Image as ImageType } from '@type-declarations/media';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import styles from './Figure.module.scss';

interface Props {
  image: ImageType;
  alt?: string;
  sizes?: string;
  caption?: string;
  className?: string | false;
  imageClassName?: string | false;
  quality?: number;
  layout?: 'fill';
}

function Figure({
  image,
  alt,
  sizes,
  caption,
  className,
  imageClassName,
  quality,
  layout,
}: Props) {
  const conditionalProps =
    layout === 'fill'
      ? { layout }
      : { width: image.width, height: image.height };

  return (
    <figure className={clsx(styles.figure, className)}>
      <Image
        src={image.url}
        placeholder="blur"
        blurDataURL={image.placeholder}
        alt={alt || image.description || ''}
        sizes={sizes}
        className={clsx(styles.image, imageClassName)}
        quality={quality}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...conditionalProps}
      />
      {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
    </figure>
  );
}

export default Figure;
