import RichText from '@atoms/RichText/RichText';
import { ButtonVariants, Cta } from '@type-declarations/cta';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import CtaButton from '../CtaButton/CtaButton';
import styles from './SectionContent.module.scss';

interface SectionContentProps extends PropsWithChildren {
  title?: string;
  punchline?: string;
  text?: string;
  cta?: Cta;
  className?: string;
  fullWidth?: boolean;
  alternativeLayout?: boolean;
  ctaVariant?: ButtonVariants;
  modifier?: 'fullSlider' | 'halfSlider' | 'textImageSection';
}

function SectionContent({
  title,
  punchline,
  text,
  cta,
  className,
  children,
  fullWidth,
  alternativeLayout = false,
  ctaVariant,
  modifier,
}: SectionContentProps) {
  if (!title && !text && !cta?.link[0].title && !children) return null;

  const hasContent = !!(text || cta?.link[0]?.title || children);
  const hasTextOrCta = !!(text || cta?.link[0]?.title);

  return (
    <div
      className={clsx(
        'u-container-grid',
        styles.container,
        hasContent && styles.hasContent,
        hasTextOrCta && styles.hasTextOrCta,
        fullWidth && styles.fullWidth,
        className,
        alternativeLayout && styles.alternativeLayout,
        modifier && styles[modifier],
      )}
    >
      {title && <h2 className={styles.title}>{title}</h2>}
      {punchline && (
        <p className={clsx('u-typography-paragraph-sm', styles.punchline)}>
          {punchline}
        </p>
      )}

      {hasContent && (
        <div className={styles.content}>
          {text && <RichText text={text} />}
          {cta && (
            <CtaButton
              cta={cta}
              className={styles.ctaButton}
              variant={ctaVariant}
            />
          )}
          {children && <div className={styles.children}>{children}</div>}
        </div>
      )}
    </div>
  );
}

export default SectionContent;
