import Figure from '@atoms/Figure/Figure';
import RichText from '@atoms/RichText/RichText';
import Section from '@molecules/Section/Section';
import QuotationMarkIcon from '@svg/quotation-mark.svg';
import { QuoteSection as Props } from '@type-declarations/sections';
import clsx from 'clsx';

import styles from './QuoteSection.module.scss';

function QuoteSection({ quote, image, authorName, authorRole }: Props) {
  return (
    <Section containerClassName="u-container-grid" smallPaddingTop>
      <div className={styles.container}>
        <QuotationMarkIcon className={styles.quotationMark} />
        <RichText
          text={quote}
          className={clsx(styles.quote, 'u-typography-heading-3')}
        />
        <div className={styles.line} />
        <div className={styles.authorContainer}>
          {image && (
            <Figure
              image={image}
              className={styles.figure}
              imageClassName={styles.image}
              layout="fill"
            />
          )}
          <div>
            <p className={styles.name}>{authorName}</p>
            <p className={styles.role}>{authorRole}</p>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default QuoteSection;
