import BigCard from '@molecules/BigCard/BigCard';
import Section from '@molecules/Section/Section';
import { CardsSection as CardsSectionProps } from '@type-declarations/sections';

import styles from './CardsSection.module.scss';

export default function CardsSection({ cards }: CardsSectionProps) {
  return (
    <Section>
      <div className={styles.container}>
        {cards.map(card => (
          <BigCard card={card} key={card.id} />
        ))}
      </div>
    </Section>
  );
}
