import { LogoSlide } from '@type-declarations/slider';
import Image from 'next/legacy/image';

import styles from './LogoCard.module.scss';

interface Props {
  item: LogoSlide;
}

export default function ImageCard({ item }: Props) {
  return (
    <article className={styles.card}>
      <div className={styles.imageContainer}>
        <Image
          src={item.image.url}
          placeholder="blur"
          blurDataURL={item.image.placeholder}
          width={400}
          height={400}
          alt={item.title || item.image.description}
          className={styles.image}
        />
      </div>
    </article>
  );
}
