import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './Loader.module.scss';

interface Props extends PropsWithChildren {
  loading: boolean;
  className?: string;
  modifier?: 'verticalPadding';
}

export default function Loader({
  loading,
  className,
  children,
  modifier,
}: Props) {
  return (
    <div className={clsx(styles.container, modifier && styles[modifier])}>
      {children}
      {loading && (
        <div className={styles.overlay}>
          <div
            className={clsx(
              styles,
              'loader',
              className,
              !children && styles.absolute,
            )}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 20 20"
              className={styles.spinner}
            >
              <circle
                cx="10"
                cy="10"
                r="8"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                pathLength="10"
                strokeDasharray="8 2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
}
