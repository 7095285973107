import { SECTIONS } from '@constants/sections';
import { Section } from '@type-declarations/sections';
import { imageSliderSectionMap } from '@utils/maps/sectionMaps';

interface Props {
  sections: {
    sections: Section[];
  };
}

function Sections({ sections: s }: Props) {
  const { sections = [] } = s || {};
  if (!sections.length) return null;

  const mappedSections = sections.map(section => {
    if (section?.typename === 'ImageSliderSection') {
      return imageSliderSectionMap(section);
    }

    return section;
  });

  return mappedSections.map(section => {
    // TODO: REMOVE ANY TYPE. Make sure we have the correct SectionProps instead of a generic Section type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Component: any = SECTIONS[section?.typename];
    if (!Component) return null;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component key={`${section.id}`} {...section} />;
  });
}

export default Sections;
