import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { IframeSection as Props } from '@type-declarations/sections';

export default function IframeSection({
  title,
  punchline,
  text,
  iframeSource,
  iframeName,
  cta,
  iframeHeight,
  id,
}: Props) {
  const formattedSource = iframeSource
    .replaceAll(/“/g, '"')
    .replaceAll(/”/g, '"')
    .replaceAll(/‘/g, "'")
    .replaceAll(/’/g, "'");

  return (
    <Section id={id}>
      <SectionContent
        title={title}
        punchline={punchline}
        text={text}
        ctaVariant="tertiary"
        cta={cta}
      >
        <iframe
          data-gtm-iframe-name={iframeName}
          title={title}
          src={formattedSource}
          width="100%"
          height={iframeHeight || 800}
        />
      </SectionContent>
    </Section>
  );
}
