import ContentCard from '@molecules/ContentCard/ContentCard';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { ContentGridSection as Props } from '@type-declarations/sections';
import clsx from 'clsx';

import styles from './ContentGridSection.module.scss';

export default function ContentGridSection({
  id,
  title,
  text,
  cta,
  content,
  noPadding,
}: Props) {
  return (
    <Section noPadding={noPadding} id={id}>
      <SectionContent
        title={title}
        text={text}
        cta={cta}
        ctaVariant="primary"
        fullWidth
        alternativeLayout
      />

      <div
        className={clsx(
          styles.grid,
          'u-mt-10',
          'u-grid',
          'u-grid--2@md',
          'u-grid--3@xl',
        )}
      >
        {content.map((item, index) => (
          <ContentCard
            key={item.id}
            item={item}
            first={index === 0}
            last={index === content.length - 1}
          />
        ))}
      </div>
    </Section>
  );
}
