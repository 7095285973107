import ContentCard from '@molecules/ContentCard/ContentCard';
import SwiperSection from '@molecules/SwiperSection/SwiperSection';
import { ContentSliderSection as Props } from '@type-declarations/sections';

export default function ContentSliderSection({
  title,
  text,
  cta,
  content,
}: Props) {
  return (
    <SwiperSection
      title={title}
      text={text}
      cta={cta}
      selection={content}
      variant="fullSlider"
      SliderCard={ContentCard}
    />
  );
}
