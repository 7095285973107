import Figure from '@atoms/Figure/Figure';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import styles from '@organisms/TextImageSection/TextImageSection.module.scss';
import { TextImageSection as Props } from '@type-declarations/sections';
import clsx from 'clsx';

function TextImageSection({
  title,
  punchline,
  text,
  cta,
  noPadding,
  image,
  imageAlt,
}: Props) {
  return (
    <Section
      noPadding={noPadding}
      containerClassName={clsx(styles.container, 'u-container-grid')}
    >
      <SectionContent
        title={title}
        punchline={punchline}
        text={text}
        cta={cta}
        ctaVariant="primary"
        modifier="textImageSection"
        className={styles.content}
      />

      <Figure
        image={image}
        alt={imageAlt}
        sizes="(min-width: 1440px) 576px, (min-width: 1280px) 512px, (min-width: 1024px) 470px, 100vw"
        className={styles.figure}
        imageClassName={styles.image}
        quality={100}
      />
    </Section>
  );
}

export default TextImageSection;
