import LogoCard from '@molecules/SliderCard/LogoCard';
import SwiperSection from '@molecules/SwiperSection/SwiperSection';
import { LogoSliderSection as Props } from '@type-declarations/sections';

export default function LogoSliderSection({
  title,
  punchline,
  text,
  cta,
  selection,
  noPadding,
}: Props) {
  return (
    <SwiperSection
      title={title}
      punchline={punchline}
      text={text}
      cta={cta}
      selection={selection}
      noPadding={noPadding}
      SliderCard={LogoCard}
      slideSize="sm"
      autoplay
    />
  );
}
