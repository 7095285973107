import Figure from '@atoms/Figure/Figure';
import { MAX_CONTAINER_WIDTH } from '@constants/constants';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { ImageSection as Props } from '@type-declarations/sections';
import clsx from 'clsx';

import styles from './ImageSection.module.scss';

function ImageSection({ title, text, cta, image, imageAlt, big }: Props) {
  const { url, width, height, placeholder } = image;

  return (
    <Section>
      <SectionContent
        title={title}
        text={text}
        cta={cta}
        ctaVariant="primary"
      />

      <div
        className={clsx(
          'u-container-grid',
          styles.imageWrapper,
          (title || text || cta) && styles.hasContent,
        )}
      >
        <Figure
          image={{
            id: image.id,
            url,
            width,
            height,
            placeholder,
          }}
          alt={imageAlt}
          sizes={
            big
              ? '(min-width: 1440px) 816px, (min-width: 1280px) 727px, (min-width: 1024px) 665px, (min-width: 768px) 903px, (min-width: 640px) 719px, 100vw'
              : `(min-width: 1440px) ${MAX_CONTAINER_WIDTH}px, 100vw`
          }
          className={clsx(styles.figure, !big && styles.small)}
          imageClassName={styles.image}
          quality={100}
        />
      </div>
    </Section>
  );
}

export default ImageSection;
