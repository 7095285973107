import useLocale from '@hooks/useLocale';
import useTranslations from '@hooks/useTranslations';
import Card from '@molecules/Card/Card';
import CtaButton from '@molecules/CtaButton/CtaButton';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { DownloadSection as DownloadSectionProps } from '@type-declarations/sections';
import getInternalRoute from '@utils/getInternalRoute';

import styles from './DownloadSection.module.scss';

function DownloadSection({
  id,
  title,
  punchline,
  text,
  pages,
  cta,
}: DownloadSectionProps) {
  const locale = useLocale();
  const t = useTranslations();

  return (
    <Section id={id}>
      <SectionContent
        title={title}
        punchline={punchline}
        text={text}
        fullWidth
        alternativeLayout
      />
      <div className={styles.container}>
        <div className="u-grid u-grid--card-grid u-grid--2@sm u-grid--3@lg">
          {pages.map(page => (
            <Card
              key={page.id}
              title={page.overviewInfo.title || page.title}
              subtitle={page.overviewInfo.type}
              image={page.overviewInfo.image}
              focusPoint={page.overviewInfo.focusPoint}
              href={getInternalRoute({
                internalLink: {
                  page: [
                    {
                      id: page.id,
                      slug: page.slug,
                      typename: 'ContentPage',
                    },
                  ],
                },
                locale,
              })}
              ctaLabel={`${t.watch}${page.overviewInfo.type ? ` ${page.overviewInfo?.type?.toLowerCase()}` : ''}`}
            />
          ))}
        </div>
        {cta && <CtaButton cta={cta} variant="primary" />}
      </div>
    </Section>
  );
}

export default DownloadSection;
