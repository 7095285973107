import Figure from '@atoms/Figure/Figure';
import RichText from '@atoms/RichText/RichText';
import { BG_COLOR_LIME } from '@constants/constants';
import CtaButton from '@molecules/CtaButton/CtaButton';
import Section from '@molecules/Section/Section';
import { ContactSection as ContactSectionProps } from '@type-declarations/sections';
import clsx from 'clsx';

import styles from './ContactSection.module.scss';

export default function ContactSection({
  contactPerson,
  backgroundColor,
}: ContactSectionProps) {
  const { id, title, punchline, text, image, cta } = contactPerson[0];

  return (
    <Section id={id}>
      <div className={styles.wrapper}>
        <div
          className={clsx(
            styles.container,
            backgroundColor === BG_COLOR_LIME && styles.lime,
          )}
        >
          <div className={styles.infoContainer}>
            <Figure
              image={image}
              className={styles.figure}
              imageClassName={styles.image}
              layout="fill"
            />

            <div className={styles.content}>
              <h2 className={styles.title}>
                <span>{punchline}</span>
                <br />
                <span>{title}</span>
              </h2>
              <RichText text={text} />
            </div>
          </div>

          {cta && (
            <CtaButton
              cta={cta}
              className={styles.cta}
              variant={
                backgroundColor === BG_COLOR_LIME ? 'secondary' : 'primary'
              }
              icon="arrow-right"
            />
          )}
        </div>
      </div>
    </Section>
  );
}
