import CtaButton from '@molecules/CtaButton/CtaButton';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import { LinkListSection as Props } from '@type-declarations/sections';

import styles from './LinkListSection.module.scss';

export default function LinkListSection({
  id,
  title,
  text,
  cta,
  list,
  noPadding,
}: Props) {
  return (
    <Section
      noPadding={noPadding}
      id={id}
      containerClassName="u-grid u-grid--2@lg"
    >
      <SectionContent
        title={title}
        text={text}
        cta={cta}
        ctaVariant="tertiary"
        fullWidth
        alternativeLayout
      />

      <div className={styles.list}>
        {list.map(item => (
          <CtaButton
            key={item.id}
            cta={item}
            className={styles.ctaButton}
            icon="arrow-right"
            variant="list"
          />
        ))}
      </div>
    </Section>
  );
}
