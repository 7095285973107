import SliderCard from '@molecules/SliderCard/SliderCard';
import SwiperSection from '@molecules/SwiperSection/SwiperSection';
import { RelatedPagesSection as Props } from '@type-declarations/sections';

export default function RelatedPagesSection({
  id,
  title,
  punchline,
  text,
  cta,
  selection = [],
  noPadding,
  noBorderTop,
}: Props) {
  return (
    <SwiperSection
      id={id || undefined}
      title={title}
      punchline={punchline}
      text={text}
      cta={cta}
      selection={selection}
      noPadding={noPadding}
      noBorderTop={noBorderTop}
      SliderCard={SliderCard}
    />
  );
}
