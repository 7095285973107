import CardsSection from '@organisms/CardsSection/CardsSection';
import ContactSection from '@organisms/ContactSection/ContactSection';
import ContentGridSection from '@organisms/ContentGridSection/ContentGridSection';
import ContentSliderSection from '@organisms/ContentSliderSection/ContentSliderSection';
import DownloadSection from '@organisms/DownloadSection/DownloadSection';
import EventSection from '@organisms/EventSection/EventSection';
import FaqSection from '@organisms/FaqSection/FaqSection';
import IframeSection from '@organisms/IframeSection/IframeSection';
import ImageSection from '@organisms/ImageSection/ImageSection';
import ImageSliderSection from '@organisms/ImageSliderSection/ImageSliderSection';
import LinkListSection from '@organisms/LinkListSection/LinkListSection';
import LogoSliderSection from '@organisms/LogoSliderSection/LogoSliderSection';
import QuoteSection from '@organisms/QuoteSection/QuoteSection';
import RelatedPagesSection from '@organisms/RelatedPagesSection/RelatedPagesSection';
import TextImageSection from '@organisms/TextImageSection/TextImageSection';
import TextSection from '@organisms/TextSection/TextSection';
import VideoSection from '@organisms/VideoSection/VideoSection';

export const SECTIONS = {
  ImageSection,
  TextSection,
  TextImageSection,
  VideoSection,
  IframeSection,
  DownloadSection,
  ContactSection,
  CardsSection,
  RelatedPagesSection,
  ImageSliderSection,
  QuoteSection,
  EventSection,
  ContentSliderSection,
  ContentGridSection,
  LinkListSection,
  FaqSection,
  LogoSliderSection,
};
