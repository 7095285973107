import formatDate from '@utils/formatDate';

interface Props {
  date: string;
  format?: string;
  className?: string;
}

export default function Time({ date, format, className }: Props) {
  return (
    <time dateTime={date} className={className}>
      {formatDate({ date, format })}
    </time>
  );
}
