import Link from '@atoms/Link/Link';
import Time from '@atoms/Time/Time';
import { DEFAULT_FOCUS_POINT, FOCUS_POINT } from '@constants/constants';
import AnchrLogo from '@svg/logo.svg';
import { Image as ImageType, ImageAlignment } from '@type-declarations/media';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import { PropsWithChildren } from 'react';

import styles from './Card.module.scss';

interface CardProps extends PropsWithChildren {
  title: string;
  href: string;
  image?: ImageType;
  date?: string;
  subtitle?: string;
  className?: string;
  sizes?: string;
  ctaLabel?: string;
  focusPoint?: ImageAlignment;
}

function Card({
  title,
  image,
  date,
  subtitle,
  href,
  ctaLabel,
  className,
  children,
  sizes = '(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw',
  focusPoint = DEFAULT_FOCUS_POINT,
}: CardProps) {
  return (
    <article className={clsx(styles.card, className)}>
      <div className={styles.imageContainer}>
        {image ? (
          <Image
            src={image.url}
            placeholder="blur"
            blurDataURL={image.placeholder}
            sizes={sizes}
            alt={image.description || ''}
            objectFit="cover"
            objectPosition={FOCUS_POINT[focusPoint]}
            layout="fill"
            className={styles.image}
          />
        ) : (
          <div className={styles.placeholder}>
            <AnchrLogo className={styles.logo} />
          </div>
        )}
      </div>

      <div className={styles.content}>
        {date && (
          <Time
            date={date}
            className={clsx(
              styles.subtitle,
              'u-typography-paragraph-xs u-color-blue-900 u-mb-1@from-sm u-mb-2',
            )}
          />
        )}

        {subtitle && (
          <p
            className={clsx(
              styles.subtitle,
              'u-typography-paragraph-xs u-color-blue-900 u-mb-1@from-sm u-mb-2',
            )}
          >
            {subtitle}
          </p>
        )}

        <h1 className="u-typography-heading-card u-color-blue-900 u-mb-0">
          {title}
        </h1>

        {children && <div>{children}</div>}

        {ctaLabel && (
          <div className={styles.ctaContainer}>
            <Link href={href} className={clsx(styles.cta, 'u-umbrella-link')}>
              {ctaLabel}
            </Link>
          </div>
        )}
      </div>
    </article>
  );
}

export default Card;
