import useLocale from '@hooks/useLocale';
import useTranslations from '@hooks/useTranslations';
import Card from '@molecules/Card/Card';
import { Cases } from '@type-declarations/overviewItems';
import getInternalRoute from '@utils/getInternalRoute';

interface Props {
  item: Cases;
}

export default function CaseItem({ item }: Props) {
  const locale = useLocale();
  const t = useTranslations();
  const subtitle = item.solutions?.length
    ? item.solutions[0].title
    : t.generalCase;

  return (
    <Card
      title={item.overviewInfo.title || item.title}
      subtitle={subtitle}
      ctaLabel={t.readMore}
      image={item.overviewInfo.image}
      focusPoint={item.overviewInfo.focusPoint}
      href={getInternalRoute({
        internalLink: { page: [item] },
        locale,
      })}
    />
  );
}
