import { Question as QuestionType } from '@type-declarations/faq';
import { useState } from 'react';

import Question from './Question';

interface Props {
  questions: QuestionType[];
  firstQuestionOpen?: boolean;
}

export default function Questions({
  questions,
  firstQuestionOpen = false,
}: Props) {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(
    firstQuestionOpen ? 0 : null,
  );

  return (
    <dl className="u-list-clean u-grid u-grid--gap-0 u-mb-0">
      {questions.map((item, i) => (
        <Question
          item={item}
          key={item.id}
          id={item.id}
          isOpen={expandedIndex === i}
          handleAccordion={() =>
            setExpandedIndex(i === expandedIndex ? null : i)
          }
        />
      ))}
    </dl>
  );
}
