import type { MuxPlayerRefAttributes } from '@mux/mux-player-react';
import { RefObject, useState } from 'react';

const useMedia = ({
  reference,
}: {
  reference: RefObject<MuxPlayerRefAttributes>;
}) => {
  const [isMediaPlaying, setIsMediaPlaying] = useState(false);

  const play = () => {
    reference.current?.play();
    setIsMediaPlaying(true);
  };

  const pause = () => {
    reference.current?.pause();
    setIsMediaPlaying(false);
  };

  return { isMediaPlaying, play, pause };
};

export default useMedia;
