import CtaButton from '@molecules/CtaButton/CtaButton';
import { ImageSlide } from '@type-declarations/slider';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import styles from './SliderCard.module.scss';

interface Props {
  item: ImageSlide;
}

export default function ImageCard({ item }: Props) {
  return (
    <article className={styles.card}>
      {item.title && (
        <h1 className={clsx(styles.title, 'u-typography-heading-3')}>
          {item.title}
        </h1>
      )}

      {item.text && (
        <p className={clsx(styles.text, 'u-typography-paragraph')}>
          {item.text}
        </p>
      )}

      <CtaButton
        cta={item.cta}
        className={clsx(styles.cta, 'u-umbrella-link')}
        icon="arrow-right"
        variant="quaternary"
      />

      <div className={styles.imageContainer}>
        <div className={styles.gradient} />
        <Image
          src={item.image.url}
          placeholder="blur"
          blurDataURL={item.image.placeholder}
          objectFit="cover"
          objectPosition="center"
          layout="fill"
          alt={item.image.description || ''}
          className={styles.image}
        />
      </div>
    </article>
  );
}
